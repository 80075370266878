import { useState, useEffect } from 'react';
import "./GetInTouch.css";
import InputField from './InputField/InputField';
import ContactLink from './ContactLink/ContactLink';
import MessageModal from './MessageModal/MessageModal';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Link } from 'react-router-dom'



function GetInTouch() {

    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        setViewport(document.documentElement.clientWidth);
    });


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone_number: '',
        subject: '',
        message: ''
    });
    const [submissionStatus, setSubmissionStatus] = useState(null);
    useEffect(() => {}, [submissionStatus]); // Runs when submissionStatus changes


    const [inputText, setInputText] = useState("");
    const [input_name, setInput_name] = useState("");
    const [input_email, setInput_email] = useState("");
    const [input_phone, setInput_phone] = useState("");
    const [input_subject, setInput_subject] = useState("");



    document.addEventListener('invalid', ((e) => {
        return (e) => {
            //prevent the browser from showing default error bubble / hint
            e.preventDefault();
        };
    })(), true);

    function validityMessageShow(e) {
        let validityMessage = (e?.target.parentElement).nextElementSibling;
        validityMessage.innerHTML = "Please fill out this field";

        validityMessage.style.display = "block";

    }

    function validityMessageHide(e) {
        let validityMessage = (e?.target.parentElement).nextElementSibling;
        validityMessage.innerHTML = "";
        validityMessage.style.display = "none";
    }
    function nameValidation(input) {
        const regex = /^[a-zA-Z]+$/;
        return regex.test(input.replace(/\s+/g, ""));
    }
    function phoneValidation(input) {
        const phoneNumber = parsePhoneNumberFromString(input);
        return phoneNumber && phoneNumber.isValid;

    }
    function phone_nameValidation() {
        const nameInput = document.getElementById("name");
        let bothAreValid = true;

        if (!nameValidation(nameInput.value)) {
            let nameValidityMessage = (nameInput.nextElementSibling).nextElementSibling;
            nameValidityMessage.innerHTML = "Please enter a valid name";
            nameValidityMessage.style.display = "block";
            bothAreValid = false;
        }

        const phoneInput = document.getElementById("phone");
        if (!phoneValidation(phoneInput.value)) {
            let validityMessage = (phoneInput.nextElementSibling).nextElementSibling;
            validityMessage.innerHTML = "Please enter a valid phone number";
            validityMessage.style.display = "block";
            bothAreValid = false;
        }
        return bothAreValid;

    }



    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the phone name is valid (your custom validation function)
        if (!phone_nameValidation()) {
            return;
        }



        // Clear any validity messages (assuming you have some)
        let validityMessages = document.getElementsByClassName("validity-message");
        Array.from(validityMessages).forEach((message) => {
            message.innerHTML = "";
            message.style.display = "none";
        });

        // Reset message container size (specific to your use case)
        document.getElementById("text-message").style.height = "";

       
        // Send form data to the server using the fetch API
        try {
            const response = await fetch("https://improvismail.com:8010/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)  // Send the form data as JSON
            });

            if (!response || !response.ok) {
                throw new Error("Failed to submit form");
            }

            const result = await response.json();
            
            //post-submission logic
            setSubmissionStatus("success");

        } catch (error) {
            setSubmissionStatus("error");
        }

        // Clear form input fields 
        let inputs = document.getElementsByClassName("inputField");

        Array.from(inputs).forEach(input => {
            if (!(input.id === "text-message-container")) {
                input.value = "";
            } else {
                let textMessage = input.firstElementChild;
                textMessage.value = "";
            }
        })


        setInputText("");
        setInput_email("");
        setInput_name("");
        setInput_phone("");
        setInput_subject("");

    };

    const handleChange = (e) => {
        const { name, value } = e.target;  // Get name and value from the event target
        setFormData({
            ...formData,  // Copy existing form data
            [name]: value // Update the specific field that changed
        });
    };


    function handleFocus(e) {
        e.target.parentElement.style.border = `0.052vw solid var(--Red-General, #8C0709)`
    }

    function inputBlur(e) {
        if (e.target.value.trim() === "") {
            e.target.value = ""
            e.target.style.height = "";
        }
        setInputText(e.target.value);
        if (e.target.id === "text-message") {
            e.target.parentElement.style.border = "0"
        }
    }

    function addPhonePlusSymbol(e) {
        if (e.target.value.indexOf('+') !== 0) {
            e.target.value = '+' + e.target.value.replace(/\+/g, '');
        }
    };

    function removePhonePlusSymbol(e) {
        if (e.target.value === "+" || e.target.value.trim() === "+") {
            e.target.value = "";
            setInput_phone("");
        }
    };


    function handleFormFocus(e) {
        const inputs = document.querySelectorAll('input, textarea'); // Include textareas

        inputs.forEach(input => {
            input.addEventListener('input', () => {

                if (input.id === "email") {
                    setInput_email(input.value)
                } else if (input.id === "name") {
                    setInput_name(input.value)
                } else if (input.id === "phone") {
                    setInput_phone(input.value)
                } else if (input.id === "text-message") {
                    setInputText(input.value)
                } else if (input.id === "subject") {
                    setInput_subject(input.value)
                }
            })


        })
    }



    let containerClasses = `section getInTouch-container ${viewport >= 992 ? "desktop" : "mobile"}`;

    let headerClasses = `header ${viewport >= 992 ? "desktop" : "mobile"}`;

    let innerClasses = `container-fluid inn-container ${viewport >= 992 ? "desktop" : "mobile"}`;
    let innerRowClasses = `row inn-row ${viewport >= 992 ? "desktop" : "mobile"}`;
    let formClasses = `form  ${viewport >= 992 ? "desktop" : "mobile"}`;
    let inputGroupClasses = `input-group  ${viewport >= 992 ? "desktop" : "mobile"}`;
    let buttonClasses = `submit-button ${viewport >= 992 ? "desktop" : "mobile"}`

    let contactClasses = `contact  ${viewport >= 992 ? "desktop" : "mobile"}`;
    let bigLogoClasses = `big-logo ${viewport >= 992 ? "desktop" : "mobile"}`;
    let validityClasses = `validity-message  validity-message-textarea ${viewport >= 992 ? "desktop" : "mobile"}`;
    let textareaContainerClasses = `inputField  ${inputText !== "" ? "input-present" : ""} ${viewport >= 992 ? "desktop" : "mobile"}`
    let textareaClasses = ` ${inputText !== "" ? "input-present" : ""} ${viewport >= 992 ? "desktop" : "mobile"}`
    let placeholderClasses = `placeholder ${inputText !== "" ? "input-present" : ""} ${viewport >= 992 ? "desktop" : "mobile"} }`
    let iconGroupClases = `icon-group  ${viewport >= 992 ? "desktop" : "mobile"}`;

    return <div className={containerClasses} id='getInTouch'>
        <img className={bigLogoClasses} src="./images/getInTouch/ArmorX logo.svg" alt="" />
        <h2 className={headerClasses}>Get in touch</h2>
        <div className={innerClasses}>
            <div className={innerRowClasses}>
                <form className={formClasses} onSubmit={handleSubmit} id="contact-form" action='' method='' onFocus={handleFormFocus}>
                    <div className={inputGroupClasses}>
                        <InputField
                            name="name"
                            type={`text`}
                            placeholder={`Name`}
                            id={"name"}
                            setFormData={setFormData}
                            formData={formData}
                            setInput={setInput_name}
                            inputText={input_name}
                            class="col-12 col-lg-6" />
                        <InputField
                            name="email"
                            type={`email`}
                            placeholder={`Email`}
                            id={"email"}
                            setFormData={setFormData}
                            formData={formData}
                            setInput={setInput_email}
                            inputText={input_email}
                            class="col-12 col-lg-6" />

                    </div>
                    <div className={inputGroupClasses}>
                        <InputField
                            name="phone_number"
                            type={`tel`}
                            placeholder={`Phone Number`}
                            id={"phone"}
                            setFormData={setFormData}
                            formData={formData}
                            setInput={setInput_phone}
                            inputText={input_phone}
                            addPlusSymbol={addPhonePlusSymbol}
                            removePlusSymbol={removePhonePlusSymbol}
                            class="col-12 col-lg-6" />
                        <InputField
                            name="subject"
                            type={`text`}
                            placeholder={`Subject`}
                            id={"subject"}
                            setFormData={setFormData}
                            formData={formData}
                            setInput={setInput_subject}
                            inputText={input_subject}
                            class="col-12 col-lg-6" />
                    </div>
                    <div className={`group ${viewport >= 992 ? "desktop" : "mobile"}`}>
                        <div
                            className={textareaContainerClasses}
                            id="text-message-container"
                            onMouseDown={(e) => {
                                e.preventDefault(); 
                                e.target.firstElementChild?.focus() 
                            }
                            }
                        >
                            <textarea
                                name='message'
                                className={textareaClasses}
                                id="text-message"
                                onFocus={handleFocus}
                                onMouseDown={(e) => {e.stopPropagation(); e.target.focus() }}
                                onInput={(e) => { validityMessageHide(e) }}
                                onInvalid={validityMessageShow}
                                onBlur={inputBlur}
                                onChange={handleChange}
                                required
                            />
                            <label className={placeholderClasses}>Text Message</label>
                        </div>
                        <span className={validityClasses}></span>
                    </div>
                    <span className={validityClasses}></span>
                    <input className={buttonClasses} type="submit" value="Submit" onClick={phone_nameValidation} />
                </form>

                <div className={contactClasses}>
                    <div className="bottom">
                        <span className={iconGroupClases}>
                            <ContactLink href={"https://am.linkedin.com/company/improvis"} icon={"./images/getInTouch/Linkedin.svg"} text={""} className={"rounded"} name={"Linkedin"} animated={true} />
                            <ContactLink href={"https://www.facebook.com/improvisteam/"} icon={"./images/getInTouch/Facebook.svg"} text={""} className={"rounded"} name={"Facebook"} animated={true} />
                            <ContactLink href={"https://www.youtube.com/@ArmorX-r6c"} icon={"./images/getInTouch/Youtube.svg"} text={""} className={"rounded"} name={"Youtube"} animated={true} />
                            <ContactLink href={"https://improvis.ai/home"} icon={"./images/getInTouch/Improvis.svg"} text={""} className={"rounded"} name={"Improvis"} animated={true} />
                        </span>

                    </div>
                    <span id="privacy-policy">
                        <Link className='track-scroll' to="/privacy-policy">Privacy Policy</Link>
                        <span id="division" ></span>
                        <Link className='track-scroll' to="/terms-of-use">Terms of Use</Link>
                    </span>
                </div>
            </div>
        </div>
        <MessageModal submissionStatus = {submissionStatus} setSubmissionStatus = {setSubmissionStatus}/>
    </div>
}


export default GetInTouch;