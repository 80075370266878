import "./MessageModal.css";
import { useState } from 'react';

const MessageModal = (props) => {
const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        setViewport(document.documentElement.clientWidth);
    });
  return <>
  
      {props.submissionStatus === "success" && (
        <div className="modal fade show d-block" tabIndex="-1" id = "message-modal"  onClick={(e) => {if(e.target === e.currentTarget){props.setSubmissionStatus(null)}}}>
          <div className="modal-dialog">
            <div className="modal-content" id = "message-modal-content">
                <button id = "message-modal-close-button" className="btn-close btn-close-white"  onClick={() => props.setSubmissionStatus(null)}></button>
                <img src="./images/getInTouch/success-message.svg" alt="" id="message-modal-image"/>
                <p id="message-modal-text">Your message has successfully been sent</p>
            </div>
          </div>
        </div>
      )}
       {props.submissionStatus === "error" && (
        <div className="modal fade show d-block" tabIndex="-1" id = "message-modal" onClick={(e) => {if(e.target === e.currentTarget){props.setSubmissionStatus(null)}}}>
          <div className="modal-dialog">
            <div className="modal-content" id = "message-modal-content">
                <button id = "message-modal-close-button" className="btn-close btn-close-white"  onClick={() => props.setSubmissionStatus(null)}></button>
                <img src="./images/getInTouch/error-message.svg" alt="" id="message-modal-image"/>
                <p id="message-modal-text">Your message couldn’t be sent now.{viewport < 992? <br/>: null}Please try again later.</p>
            </div>
          </div>
        </div>
      )}
  </>
};

export default MessageModal;
