import { useState } from 'react';
import "./ContactLink.css";

function ContactLink(params) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });

    function mouseEnter(e) {
        e.target.src = `./images/getInTouch/${params.name}-hover.svg`
    }
    function mouseLeave(e) {
       
        e.target.src = `./images/getInTouch/${params.name}.svg`
    }
    function mouseDown(e) {
        e.target.src = `./images/getInTouch/${params.name}-active.svg`
    }
    let linkClasses = `link ${viewport >= 992 ? "desktop" : "mobile"} `;
    let iconClasses = `icon ${params.className} ${viewport >= 992 ? "desktop" : "mobile"} `;

    return <a className = {linkClasses} href={params.href} target="_blank">
        <img className = {iconClasses} src={params.icon}  onMouseEnter={params.animated? mouseEnter: null} onMouseLeave={params.animated? mouseLeave: null} onMouseDown={params.animated? mouseDown: null} onMouseUp={params.animated? mouseLeave: null} alt=''></img>
        <span className="contact-text">{params.text}</span>
    </a>

}

export default ContactLink;